import { useEffect, useRef } from "react";

const CONFIG = {
  IMAGE: {
    CAPTURE_INTERVAL: 5000,
    CAPTURE_HEIGHT: 480,
    CAPTURE_WIDTH: 640,
    FORMAT: "image/png"
  },
  VIDEO: {
    width: { ideal: 640, max: 640 },
    height: { ideal: 480, max: 480 },
    facingMode: "user"
  },
  NETWORK: {
    TIMEOUT_MS: 30000,
    MAX_RETRIES: 3,
    RETRY_DELAY_MS: 2000
  }
};

type UseProctoringCaptureProps = {
  enabled: boolean;
  allowCapture: boolean;
  id: string;
  hmacSecurityKey: string;
  assessment: string;
  endpoint: string;
};

const useProctoringCapture = ({
  enabled,
  allowCapture,
  id,
  hmacSecurityKey,
  assessment,
  endpoint
}: UseProctoringCaptureProps): void => {
  const signedUrlRef = useRef<{ url: string; signature: string } | null>(null);
  const didRunEffectRef = useRef(false);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const captureIntervalRef = useRef<number | null>(null);
  const imageCounterRef = useRef(1);
  const reconnectAttemptsRef = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;

  async function fetchWithTimeout(
    url: string,
    options: RequestInit,
    timeoutMs: number = CONFIG.NETWORK.TIMEOUT_MS
  ): Promise<Response> {
    const controller = new AbortController();
    const { signal } = controller;

    const timeout = setTimeout(() => {
      controller.abort();
    }, timeoutMs);

    try {
      const response = await fetch(url, { ...options, signal });
      clearTimeout(timeout);
      return response;
    } catch (error) {
      clearTimeout(timeout);
      throw error;
    }
  }

  async function fetchWithRetry(
    url: string,
    options: RequestInit,
    retries: number = CONFIG.NETWORK.MAX_RETRIES,
    timeoutMs: number = CONFIG.NETWORK.TIMEOUT_MS
  ): Promise<Response> {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt <= retries; attempt++) {
      try {
        return await fetchWithTimeout(url, options, timeoutMs);
      } catch (error) {
        lastError = error as Error;
        if (attempt < retries) {
          await new Promise(resolve =>
            setTimeout(resolve, CONFIG.NETWORK.RETRY_DELAY_MS)
          );
        }
      }
    }

    throw lastError;
  }

  async function getSignedUrl(): Promise<{
    url: string;
    signature: string;
  }> {
    try {
      const response = await fetchWithRetry(
        `${endpoint}/event/${id}/signed-url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmacSecurityKey}`
          },
          body: JSON.stringify({ assessment })
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to get signed URL: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error getting signed URL:", error);
      throw error;
    }
  }

  async function saveImageMetaData(
    fileName: string,
    capturedAt: number,
    imageCounter: number
  ): Promise<void> {
    try {
      const response = await fetchWithRetry(`${endpoint}/event/${id}/image`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hmacSecurityKey}`
        },
        body: JSON.stringify({
          eventId: id,
          fileName,
          capturedAt,
          assessment,
          order: imageCounter
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to save image metadata: ${response.status}`);
      }
    } catch (error) {
      console.error("Error saving image metadata:", error);
      throw error;
    }
  }

  async function sendImageCapture(
    fileName: string,
    blob: Blob,
    imageCounter: number,
    capturedAt: number
  ): Promise<void> {
    try {
      if (!signedUrlRef.current) {
        console.error("Signed URL not available");
        signedUrlRef.current = await getSignedUrl();

        if (!signedUrlRef.current) {
          throw new Error("Failed to refresh signed URL");
        }
      }

      const assetUrl = `${signedUrlRef.current.url}/${fileName}?${signedUrlRef.current.signature}`;

      const response = await fetchWithRetry(assetUrl, {
        method: "PUT",
        body: blob
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.status}`);
      }

      await saveImageMetaData(fileName, capturedAt, imageCounter);
    } catch (error) {
      console.error("Error in sendImageCapture:", error);
      throw error;
    }
  }

  async function captureImage(): Promise<void> {
    const canvas = document.querySelector('canvas[data-proctoring="true"]');
    const video = document.querySelector('video[data-proctoring="true"]');

    if (!canvas || !video) {
      console.error("Canvas or video element not found");
      return;
    }

    const context = (canvas as HTMLCanvasElement).getContext("2d");
    if (context) {
      context.drawImage(
        video as HTMLVideoElement,
        0,
        0,
        CONFIG.IMAGE.CAPTURE_WIDTH,
        CONFIG.IMAGE.CAPTURE_HEIGHT
      );

      const dataUrl = (canvas as HTMLCanvasElement).toDataURL(
        CONFIG.IMAGE.FORMAT
      );
      try {
        const blob = await (await fetch(dataUrl)).blob();
        const capturedAt = Date.now();
        const fileName = `webcam_${capturedAt}_${id}.png`;
        await sendImageCapture(
          fileName,
          blob,
          imageCounterRef.current,
          capturedAt
        );
        imageCounterRef.current++;
      } catch (error) {
        console.error("Error capturing and uploading image:", error);
      }
    }
  }

  const initMediaRef = useRef<() => Promise<void>>();
  const handleTrackEndedRef = useRef<() => Promise<void>>();

  handleTrackEndedRef.current = async (): Promise<void> => {
    console.warn("Camera track ended. Attempting to reconnect...");

    if (captureIntervalRef.current) {
      clearInterval(captureIntervalRef.current);
      captureIntervalRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
      mediaStreamRef.current = null;
    }

    if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
      reconnectAttemptsRef.current++;
      if (initMediaRef.current) {
        await initMediaRef.current();
      }
    } else {
      console.error(
        `Failed to reconnect camera after ${MAX_RECONNECT_ATTEMPTS} attempts`
      );
      // TODO: Implement a callback to notify the user that proctoring has failed AST-3535
    }
  };

  initMediaRef.current = async (): Promise<void> => {
    try {
      const video = document.querySelector('video[data-proctoring="true"]');
      if (!video) {
        console.error("Video element not found");
        return;
      }

      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false
      });

      (video as HTMLVideoElement).srcObject = mediaStreamRef.current;
      await (video as HTMLVideoElement).play();

      reconnectAttemptsRef.current = 0;

      mediaStreamRef.current.getVideoTracks().forEach(track => {
        if (handleTrackEndedRef.current) {
          const eventHandler = () => {
            if (handleTrackEndedRef.current) {
              handleTrackEndedRef.current();
            }
          };
          track.addEventListener("ended", eventHandler as EventListener);
        }
      });

      if (allowCapture) {
        signedUrlRef.current = await getSignedUrl();

        if (captureIntervalRef.current) {
          clearInterval(captureIntervalRef.current);
        }
        captureIntervalRef.current = window.setInterval(
          captureImage,
          CONFIG.IMAGE.CAPTURE_INTERVAL
        );
      }
    } catch (error) {
      console.error("Error accessing camera:", error);

      if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttemptsRef.current++;
        const delayMs =
          CONFIG.NETWORK.RETRY_DELAY_MS *
          Math.pow(2, reconnectAttemptsRef.current);
        setTimeout(() => {
          if (initMediaRef.current) {
            initMediaRef.current();
          }
        }, delayMs as number);
      } else {
        console.error(
          `Failed to access camera after ${MAX_RECONNECT_ATTEMPTS} attempts`
        );
        // TODO: Implement a callback to notify the user that proctoring has failed AST-3535
      }
    }
  };

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!id || !hmacSecurityKey || !assessment || !endpoint) {
      console.error("Missing required proctoring parameters.");
      return;
    }

    if (didRunEffectRef.current) {
      return;
    }

    didRunEffectRef.current = true;

    const video = document.createElement("video");
    video.autoplay = true;
    video.playsInline = true;
    video.muted = true;
    video.style.display = "none";
    video.setAttribute("data-proctoring", "true");

    const canvas = document.createElement("canvas");
    canvas.width = CONFIG.IMAGE.CAPTURE_WIDTH;
    canvas.height = CONFIG.IMAGE.CAPTURE_HEIGHT;
    canvas.style.display = "none";
    canvas.setAttribute("data-proctoring", "true");

    document.body.appendChild(video);
    document.body.appendChild(canvas);

    if (initMediaRef.current) {
      initMediaRef.current();
    }

    return (): void => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => {
          if (handleTrackEndedRef.current) {
            const eventHandler = () => {
              if (handleTrackEndedRef.current) {
                handleTrackEndedRef.current();
              }
            };
            track.removeEventListener("ended", eventHandler as EventListener);
          }
          track.stop();
        });
        mediaStreamRef.current = null;
      }

      if (captureIntervalRef.current) {
        clearInterval(captureIntervalRef.current);
        captureIntervalRef.current = null;
      }

      const proctoringVideo = document.querySelector(
        'video[data-proctoring="true"]'
      );
      const proctoringCanvas = document.querySelector(
        'canvas[data-proctoring="true"]'
      );

      if (proctoringVideo) proctoringVideo.remove();
      if (proctoringCanvas) proctoringCanvas.remove();

      reconnectAttemptsRef.current = 0;
      imageCounterRef.current = 1;
    };
  }, [allowCapture, id, hmacSecurityKey, assessment, endpoint, enabled]);
};

export default useProctoringCapture;
