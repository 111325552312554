import { useContext, useRef, useState } from "react";
import { Lockout } from "../Interfaces/Lockout";
import { TokenContext } from "../Providers/TokenProvider";
import { Hook } from "../utils/Reference/Hook";
import { cxURL } from "../utils/constants";

type SendLockoutProps = {
  initialLockout: Lockout;
};

type SendLockoutResult = {
  isLoading: boolean;
  lockout: Lockout;
  sendLockout: () => void;
  error: boolean;
};

const useSendLockout: Hook<SendLockoutProps, SendLockoutResult> = ({
  initialLockout
}) => {
  const { eventId, assessment } = useContext(TokenContext);
  const lockout = useRef(initialLockout);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendLockout = async (nextLockoutLevel: number) => {
    const response = await fetch(`${cxURL}/e/${eventId}/lockout`, {
      method: "POST",
      body: JSON.stringify({ assessment, lockout: nextLockoutLevel }),
      keepalive: true
    });
    if (!response.ok) {
      setIsError(() => true);
    }
    const content: Lockout = await response.json();
    lockout.current = content;
    setIsLoading(() => false);
  };

  const sendLockoutHandler = async () => {
    const nextLockoutLevel = lockout.current.lockout + 1;
    setIsLoading(() => true);
    sendLockout(nextLockoutLevel);
    lockout.current = {
      ...lockout.current,
      lockout: nextLockoutLevel,
      isLockout: nextLockoutLevel >= 5
    };
  };

  return {
    isLoading,
    lockout: lockout.current,
    sendLockout: sendLockoutHandler,
    error: isError
  };
};

export default useSendLockout;
