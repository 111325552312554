import React, { createContext, FunctionComponent, useRef } from "react";
import { ProctoringSettings } from "../utils/Reference/Proctoring";

type TokenFields = {
  subTestId: string;
  eventId: string | null;
  returnUrl: string;
  assessment: string;
  proctoring: ProctoringSettings;
  hmacSecurityKey: string;
};

const DEFAULT_TOKEN = {
  subTestId: "-1",
  eventId: null,
  returnUrl: "",
  assessment: "",
  proctoring: {
    enabled: false,
    allowCapture: false,
    screenCapture: false
  },
  hmacSecurityKey: ""
} as TokenFields;

export const TokenContext = createContext<TokenFields>(DEFAULT_TOKEN);

const TokenProvider: FunctionComponent = ({ children }) => {
  const locationSearchParams = new URLSearchParams(window.location.search);
  const token = locationSearchParams.get("token");
  const data = useRef(DEFAULT_TOKEN);
  if (token) {
    try {
      data.current = JSON.parse(
        window.atob(replaceUrlChars(token))
      ) as TokenFields;
      data.current.assessment =
        new URL(data.current.returnUrl).searchParams.get("assessment") ?? "";
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <TokenContext.Provider value={data.current}>
      {children}
    </TokenContext.Provider>
  );
};

function replaceUrlChars(token: string) {
  return token.replace(/-/g, "+").replace(/_/g, "/");
}

export default TokenProvider;
