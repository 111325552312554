import React, { FunctionComponent, useContext } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import { EventDataWithTestProps } from "../Interfaces/EventData";
import TenKeyTests from "../Tests/TestDisplays/TenKeyTests";
import TypingTest from "../Tests/TestDisplays/TypingTest";
import {
  getCLIKStartingQuestion,
  getGameQuestionAndStartingId,
  getMrabStartingTask,
  getMultipleChoiceStartingQuestion,
  getPersonalityTestStartingQuestion,
  getTestMakerData
} from "../utils/redisHelpers";
import { TestType } from "../utils/Reference/TestType";
import {
  Clik,
  Game,
  MrabCast,
  MultipleChoice,
  Personality,
  TestMaker
} from "./displays";
import NetworkError from "./utility/error/network/NetworkError";
import useProctoringCapture from "../CustomHooks/useProctoringCapture";
import { TokenContext } from "../Providers/TokenProvider";
import { proctoringURL } from "../utils/constants";

const DirectAssessment: FunctionComponent<{
  testType: string;
  data: EventDataWithTestProps;
}> = ({ testType, data }) => {
  const TestEvent = useContext(TestEventContext);
  const { proctoring, hmacSecurityKey, assessment } = useContext(TokenContext);
  useProctoringCapture({
    ...proctoring,
    id: data.eventId,
    allowCapture: true,
    hmacSecurityKey: hmacSecurityKey,
    assessment: assessment,
    endpoint: proctoringURL
  });

  const { testIdsArray, testIndex } = TestEvent;
  const subTestIdAsString = String(data.subTestId);
  switch (testType) {
    case TestType.CLIK:
      return (
        <Clik
          {...data}
          resumeMethod={getCLIKStartingQuestion(
            data.testEventId,
            subTestIdAsString,
            data.token
          )}
        />
      );
    case TestType.GAME:
      return (
        <Game
          {...data}
          resumeMethod={getGameQuestionAndStartingId(
            data.testEventId,
            subTestIdAsString,
            data.token
          )}
        />
      );
    case TestType.MRAB_CAST:
      return (
        <MrabCast
          {...data}
          resumeMethod={getMrabStartingTask(
            data.testEventId,
            subTestIdAsString,
            data.token
          )}
        />
      );
    case TestType.MULTIPLE_CHOICE:
      return (
        <MultipleChoice
          {...data}
          resumeMethod={getMultipleChoiceStartingQuestion(
            data.testEventId,
            subTestIdAsString,
            data.token
          )}
        />
      );
    case TestType.PERSONALITY:
      return (
        <Personality
          {...data}
          resumeMethod={getPersonalityTestStartingQuestion(
            data.testEventId,
            subTestIdAsString,
            data.token
          )}
        />
      );
    case TestType.TEN_KEY:
      TestEvent.useLockout = false;
      return (
        <TenKeyTests
          {...{
            ...data,
            subTestId: subTestIdAsString
          }}
        />
      );
    case TestType.TEST_MAKER:
      TestEvent.useLockout = false;
      return data.testData.isTyping ? (
        <TypingTest
          {...data}
          subTestId=""
          testMakerTestId={testIdsArray[testIndex].replace("tm_", "")}
          timeAllowed={data.testData.timeAllowed}
          title={data.testData.title}
        />
      ) : (
        <TestMaker
          {...data}
          resumeMethod={getTestMakerData(
            data.testEventId,
            testIdsArray[testIndex],
            data.token,
            data.testData.showPagination
          )}
        />
      );
    case TestType.TYPING:
      TestEvent.useLockout = false;
      return (
        <TypingTest
          {...{
            ...data,
            subTestId: subTestIdAsString
          }}
          testMakerTestId=""
          timeAllowed={60}
          title="Criteria Typing Test"
        />
      );
    default:
      return <NetworkError />;
  }
};

export default DirectAssessment;
