import { useCallback, useContext, useEffect, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import { Hook } from "../utils/Reference/Hook";

type VisibilityProps = {
  hideOnInit: boolean;
  onTrigger: () => void;
};

type VisibilityResult = {
  isHidden: boolean;
  refocus: () => void;
};

const useVisibility: Hook<VisibilityProps, VisibilityResult> = ({
  hideOnInit,
  onTrigger
}) => {
  const TestEvent = useContext(TestEventContext);
  const [isHidden, setIsHidden] = useState(hideOnInit);

  const visibilityFocusChangeHandler = useCallback(
    (e: Event) => {
      const isInFocus =
        e.type === "blur" ||
        (e.type === "visibilitychange" &&
          document.visibilityState === "hidden");
      const shouldHide = isInFocus && TestEvent.useLockout;
      if (!isHidden && shouldHide) {
        onTrigger();
        setIsHidden(true);
      }
    },
    [TestEvent.useLockout, isHidden, onTrigger]
  );

  useEffect(() => {
    window.addEventListener("visibilitychange", visibilityFocusChangeHandler);
    window.addEventListener("blur", visibilityFocusChangeHandler);
    return () => {
      window.removeEventListener(
        "visibilitychange",
        visibilityFocusChangeHandler
      );
      window.removeEventListener("blur", visibilityFocusChangeHandler);
    };
  }, [visibilityFocusChangeHandler]);

  return {
    isHidden,
    refocus: () => setIsHidden(false)
  };
};

export default useVisibility;
