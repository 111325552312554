import React, { FunctionComponent, useContext } from "react";
import { Lockout } from "../../../Interfaces/Lockout";
import { LanguageContext } from "../../../Providers/LanguageProvider";
import LockoutIcon from "../../icons/lockout";
import Loader from "../../utility/loader/Loader";
import FormattedMessage from "../../utility/message/FormattedMessage";
import { Alert } from "../alert/Alert";
import { LockoutAlertMessageFinalParagraph } from "./sections/LockoutAlertMessageFinalParagraph";
import { AlertMessageSecondParagraph } from "./sections/LockoutMessageSecondParagraph";

import styles from "./Lockout.module.css";

export const LOCKOUT_DANGER_LIMIT = 2;

export const LOCKOUT_LAST_CHANCE_THRESHOLD = 4;

type LockoutProps = {
  lockout: Lockout;
  refocus: () => void;
  isLoading?: boolean;
};

const LockoutMessage: FunctionComponent<LockoutProps> = ({
  lockout,
  refocus,
  isLoading = false
}) => {
  const language = useContext(LanguageContext)["lockout"];
  if (lockout.isLockout) {
    return (
      <div className={styles["loader"]}>
        <Loader />
      </div>
    );
  }
  const isOverWarningLimit = lockout.lockout > LOCKOUT_DANGER_LIMIT;
  const bodyContent = (
    <>
      <Alert
        alertEdge="left"
        alertColor={lockout.lockout > LOCKOUT_DANGER_LIMIT ? "red" : "yellow"}
        alertTextColor="black"
        alertTextWeight="light"
        hasBoxShadow
      >
        <FormattedMessage
          message={language.LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1}
        />
        <AlertMessageSecondParagraph isOverWarningLimit={isOverWarningLimit} />
        {isOverWarningLimit && (
          <LockoutAlertMessageFinalParagraph lockout={lockout} />
        )}
      </Alert>
      <button
        className={styles["button"]}
        onClick={refocus}
        disabled={isLoading}
      >
        {language.BUTTON_RESUME}
      </button>
    </>
  );

  return (
    <div key={`lockout-${lockout.lockout}`} className={styles["page"]}>
      <div className={styles["page-content"]}>
        <LockoutIcon />
        <h1 className={styles["title"]}>{language.LOCKOUT_TITLE_PAUSED}</h1>
        <div className={styles["content-bottom"]}>{bodyContent}</div>
      </div>
    </div>
  );
};

export default LockoutMessage;
